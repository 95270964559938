import clsx from 'clsx';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

import { Container } from '../lib/components/layout/container';
import { Section } from '../lib/components/layout/section';
import { Button } from '../lib/components/navigation/button';
import { SEO } from '../lib/components/seo';
import { Heading } from '../lib/components/typography/heading';
import { Paragraph } from '../lib/components/typography/paragraph';
import { Header } from '../lib/ui/header';

const Page404 = () => {
    const {
        routes: { pages }
    } = useStaticQuery(graphql`
        query {
            routes: contentfulRoutesAll {
                pages {
                    ... on ContentfulPages {
                        id
                        title
                        slug
                    }
                    ... on ContentfulRoutesCoverageAreas {
                        id
                        title
                        slug
                    }
                    ... on ContentfulRoutesProducts {
                        id
                        title
                        subMenu {
                            id
                            title
                            subCategories {
                                id
                                title
                                slug
                            }
                        }
                    }
                }
            }
        }
    `);

    return (
        <>
            <SEO />
            <Header offset={false} routes={pages} />
            <Section className={clsx('bg-primary h-screen flex  items-center')}>
                <Container className="flex flex-col items-center">
                    <Paragraph
                        body={false}
                        color="white"
                        className={clsx(
                            'text-xxl font-bold leading-auto mb-2'
                        )}>
                        404
                    </Paragraph>
                    <Heading tagName="h1" color="white">
                        Page not found
                    </Heading>
                    <Button to="/" className="mt-5">
                        Back to home
                    </Button>
                </Container>
            </Section>
        </>
    );
};

export default Page404;
